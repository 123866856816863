
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref} from "vue";
import { additionalCost, initCost } from "@/components/jobs/Misc/types";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { useStore } from "vuex";
import {useRoute, useRouter} from "vue-router";
import { fireSwal, fireErrorSwal } from "@/components/jobs/Misc/helpers";
import PlusSign from "@/components/Icons/PlusSign.vue";
import {useAbility} from "@casl/vue";

export default defineComponent({
  name: 'additional-costs-tab',
  components: {
    LoadingSpinner,
    PlusSign
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const ability = useAbility();
    const isSaving = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const additionalCosts = ref<additionalCost[]>([]);
    const clientCurrency = computed(() => store.getters['AdditionalCostsModule/getClientCurrency']);
    const job = computed(() => store.getters['JobModule/item']);
    function addNewCost() {
      additionalCosts.value.push(initCost(route.params.id as string));
    }

    function buildAdditionalCosts(data: additionalCost[]) {
       if(data.length > 0) {
         additionalCosts.value = data;
       } else {
         addNewCost();
       }
    }

    function handleSaveCosts() {
      isSaving.value = true;
      const query = {
        jobID: route.params.id,
        costs: additionalCosts.value
      };
      store.dispatch('AdditionalCostsModule/saveAdditionalCosts', query)
          .then(response => {
            fireSwal();
            additionalCosts.value = [];
            buildAdditionalCosts(response.data.data as additionalCost[]);
          })
          .catch(() => {
            fireErrorSwal();
          })
          .finally(() => {
            isSaving.value = false;
          })
    }

    const totals = computed<string|null>(() => {
      let total = 0 as number;
      if(additionalCosts.value.length > 0) {
        const costs = additionalCosts.value.map(cost => cost.amount) as (number | string)[];
        for(let i = 0; i < costs.length; i++) {
          if(typeof costs[i] == 'number') {
            total += costs[i] as number;
          } else  {
            total += parseInt(costs[i] as string);
          }
        }
      }
      return isNaN(total) ? null : `${total} ${clientCurrency.value ? clientCurrency.value.code : ''}`;
    });
    const fetchClientCurrency = () => {
      const company = job.value.company ? job.value.company.id : null;
      const branch = job.value.book_from_branches.length > 0 ? job.value.book_from_branches[0].id : null;
      if(company && branch) {
        store.dispatch('AdditionalCostsModule/fetchClientCurrency', {company: company, branch: branch})
      }
    }
    onMounted(() => {
      const jobID = route.params.id;
      if(jobID) {
        loading.value = true;
        store.dispatch('AdditionalCostsModule/fetchAdditionalCosts', jobID)
            .then(response => {
                buildAdditionalCosts(response as additionalCost[]);
            })
            .finally(() => {
                loading.value = false;
            });
        fetchClientCurrency();
      }
    });
    onUnmounted(() => {
      store.dispatch('AdditionalCostsModule/resetState');
    })
    return {
      loading,
      job,
      totals,
      clientCurrency,
      isSaving,
      handleSaveCosts,
      addNewCost,
      additionalCosts
    }
  }
})
