/* eslint-disable @typescript-eslint/camelcase */
import {Skill} from "@/components/worker-rates/misc/types";
import {companyRate} from "@/components/clients/misc/types"

type additionalCost = {
    id: number | string;
    job_id: number;
    name: string;
    amount: number | string | null;
}

function initCost(job_id: string): additionalCost {
    return {
        id: `temp_${Math.random()}`,
        job_id: parseInt(job_id),
        name: "",
        amount: null
    }
}

type jobProjection = {
    additionalCosts: additionalCost[];
    clientInvoice: number;
    current: number;
    estimated: number;
    realClientInvoice: number;
    hasMissingRates: boolean;
}

type projectionTotals = {
    estimated: number;
    current: number;
}

type branch = {
    id: number;
    country_id: number;
    full_name: string;
    name: string
}

type jobRatesData = {
    skills: Skill[];
    rates: companyRate[],
    non_job_rates: companyRate[]
}
export { additionalCost, initCost, jobProjection, projectionTotals, branch, jobRatesData };