import Swal from "sweetalert2";

function fireSwal() {
    Swal.fire({
        toast : true,
        position: 'top-right',
        icon: 'success',
        timer: 800,
        showConfirmButton: false,
        title: "Details saved successfully"
    })
}

function fireErrorSwal() {
    Swal.fire({
        position: 'center',
        icon: 'error',
        timer: 800,
        showConfirmButton: false,
        title: "Please enter valid data"
    })
}

function fireSuccessfulDeleteToast() {
    Swal.fire({
        title: "Record deleted successfully",
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        icon: "success",
        timer: 800
    })
}
export {fireSwal, fireErrorSwal, fireSuccessfulDeleteToast};
